@font-face {
  font-family: "Dreaming Outloud";
  src: url("/src/components/fonts/dreaming-outloud-regular.otf") format("opentype");
}
#root {
  max-width: 100svw;
  overflow-x: hidden;
}

.navbar-custom {
  min-height: 100svh;
  background-color: blue;
  color: white;
  display: flex;
  align-items: flex-start;
}
.navbar-custom .container-fluid {
  flex-direction: column;
  align-items: start;
  position: sticky;
  top: 8px;
}
.navbar-custom .container-fluid .logo-box {
  margin: 20px 0;
  text-decoration: none;
  color: white;
  display: flex;
}
.navbar-custom .container-fluid .logo-box img {
  max-width: 150px;
}
.navbar-custom .container-fluid .navbar-toggler {
  transition: outline 0.3s ease;
}
.navbar-custom .container-fluid .navbar-toggler.hover, .navbar-custom .container-fluid .navbar-toggler:not(.collapsed) {
  outline: 2px solid white;
}
.navbar-custom .container-fluid .navbar-toggler .navbar-toggler-icon {
  filter: invert(1) brightness(2);
}
.navbar-custom .container-fluid .navbar-collapse .navbar-nav {
  flex-direction: column;
}
.navbar-custom .container-fluid .navbar-collapse .navbar-nav .nav-link {
  color: white;
}
.navbar-custom .container-fluid .navbar-collapse .navbar-nav .nav-link.active, .navbar-custom .container-fluid .navbar-collapse .navbar-nav .nav-link:hover {
  font-weight: 600;
  color: rgb(61, 210, 255);
}
@media screen and (max-width: 991px) {
  .navbar-custom {
    position: fixed;
    top: 0;
    min-height: 50px;
    z-index: 1;
  }
  .navbar-custom .container-fluid {
    position: relative;
    top: 0;
    height: 100%;
    flex-direction: row;
  }
  .navbar-custom .container-fluid .logo-box {
    margin: 0;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    height: 45px;
  }
  .navbar-custom .container-fluid .logo-box img {
    max-height: 100%;
  }
  .navbar-custom .container-fluid .navbar-toggler {
    position: absolute;
    right: 11px;
  }
}

.col-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.col-home .row-home .icon-animated {
  display: flex;
  justify-content: center;
  align-items: end;
}
.col-home .row-home .icon-animated .large-icon {
  width: 80%;
  margin: 30px 0;
}
@media screen and (max-width: 992px) {
  .col-home .row-home .icon-animated .large-icon {
    width: 95%;
    margin-bottom: 20px;
  }
}
.col-home .row-home .col-md-12 {
  position: relative;
}
.col-home .row-home .col-md-12 .search-form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.col-home .row-home .col-md-12 .search-form .search-input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(225, 225, 225);
  background: rgb(223, 223, 223);
  transition: all 180ms;
  font-weight: 600;
}
.col-home .row-home .col-md-12 .search-form .search-input:focus {
  background: white;
  outline: none;
  box-shadow: 1px 0 0 0 rgb(159, 159, 159), 0 0 0 1px rgb(159, 159, 159), -1px 0 0 0 rgb(159, 159, 159);
}
.col-home .row-home .col-md-12 .search-form .search-input.error {
  background: white;
  outline: 1px solid rgb(255, 0, 0);
}
.col-home .row-home .col-md-12 .search-form svg {
  position: absolute;
  right: 1.5%;
  font-size: 1.2rem;
  cursor: pointer;
}
.col-home .row-home .col-md-12 .search-form svg:hover, .col-home .row-home .col-md-12 .search-form svg:active {
  color: blue;
  transform: scale(1.1);
}
@media screen and (max-width: 992px) {
  .col-home .row-home .col-md-12 .search-form svg {
    right: 3%;
  }
}
.col-home .row-home .col-md-12 .search-form .suggestions-list {
  position: absolute;
  top: 100.9%;
  left: -0.03%;
  padding: 10px;
  width: 100.12%;
  max-height: 55svh;
  overflow-y: auto;
  z-index: 1;
  list-style: none;
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.222);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: 700;
  text-shadow: 1px 1px black;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media screen and (max-width: 992px) {
  .col-home .row-home .col-md-12 .search-form .suggestions-list {
    max-height: 60svh;
  }
}
.col-home .row-home .col-md-12 .search-form .suggestions-list .title {
  color: white;
  font-size: 1.3rem;
}
.col-home .row-home .col-md-12 .search-form .suggestions-list .tags {
  font-size: 0.8rem;
  color: rgb(212, 212, 212);
}
.col-home .row-home .col-md-12 .search-form .has-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.col-home .row-home .category-row {
  justify-content: center;
}
.col-home .row-home .category-row .category-block {
  max-width: 19.1%;
  border-radius: 10px;
  position: relative;
  height: 0;
  padding-bottom: 18%;
  overflow: hidden;
  color: white;
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}
.col-home .row-home .category-row .category-block:nth-child(1) {
  background: rgb(140, 82, 255);
}
.col-home .row-home .category-row .category-block:nth-child(2) {
  background: rgb(255, 145, 77);
}
.col-home .row-home .category-row .category-block:nth-child(3) {
  background: rgb(82, 204, 208);
}
.col-home .row-home .category-row .category-block:nth-child(4) {
  background: rgb(255, 87, 87);
}
.col-home .row-home .category-row .category-block:nth-child(5) {
  background: rgb(56, 204, 135);
}
.col-home .row-home .category-row .category-block:nth-child(6) {
  background: rgb(82, 113, 255);
}
.col-home .row-home .category-row .category-block:nth-child(7) {
  background: #ff74ba;
}
.col-home .row-home .category-row .category-block:nth-child(8) {
  background: rgb(166, 166, 166);
}
.col-home .row-home .category-row .category-block:nth-child(9) {
  background: rgb(240, 210, 87);
  color: rgb(207, 30, 43);
}
.col-home .row-home .category-row .category-block:nth-child(10) {
  background: rgb(193, 255, 114);
  color: rgb(57, 87, 10);
}
.col-home .row-home .category-row .category-block .text-block {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.col-home .row-home .category-row .category-block .text-block .svg-inline--fa {
  height: 3em;
}
@media screen and (max-width: 423px) {
  .col-home .row-home .category-row .category-block .text-block .svg-inline--fa {
    height: 2.5em;
  }
}
@media screen and (max-width: 992px) {
  .col-home .row-home .category-row .category-block {
    max-width: 24%;
    padding-bottom: 22%;
  }
}
@media screen and (max-width: 992px) and (max-width: 768px) {
  .col-home .row-home .category-row .category-block {
    max-width: 32%;
    padding-bottom: 28%;
  }
}
@media screen and (max-width: 992px) and (max-width: 577px) {
  .col-home .row-home .category-row .category-block {
    max-width: 46.5%;
    padding-bottom: 35%;
  }
}
@media screen and (max-width: 992px) and (max-width: 577px) and (max-width: 423px) {
  .col-home .row-home .category-row .category-block {
    max-width: 44.5%;
    padding-bottom: 50%;
  }
}
.col-home .row-home .category-row .category-block:hover svg {
  transition: all linear 150ms;
  transform: scale(1.2);
}
@media screen and (max-width: 992px) {
  .col-home .row-home {
    margin: 50px 0 30px 0;
  }
}

.col-category .row {
  padding: 12.5px 0;
}
.col-category .row .category-title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
}
.col-category .row .category-title a {
  position: absolute;
  font-size: 35px;
  left: 1.8%;
  transition: all 350ms;
}
.col-category .row .category-title a:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 576px) {
  .col-category .row .category-title a {
    left: 3%;
  }
}
.col-category .row .category-title a.animais {
  color: rgb(140, 82, 255);
}
.col-category .row .category-title a.impactos {
  color: rgb(255, 145, 77);
}
.col-category .row .category-title a.clima {
  color: rgb(82, 204, 208);
}
.col-category .row .category-title a.acao {
  color: rgb(255, 87, 87);
}
.col-category .row .category-title a.sons {
  color: rgb(56, 204, 135);
}
.col-category .row .category-title a.estado_humano {
  color: rgb(82, 113, 255);
}
.col-category .row .category-title a.toque {
  color: #ff74ba;
}
.col-category .row .category-title a.eletronico {
  color: rgb(166, 166, 166);
}
.col-category .row .category-title a.emocao {
  color: rgb(240, 210, 87);
}
.col-category .row .category-title a.comer_beber {
  color: rgb(193, 255, 114);
}
.col-category .row .category-title.animais {
  color: rgb(140, 82, 255);
}
.col-category .row .category-title.impactos {
  color: rgb(255, 145, 77);
}
.col-category .row .category-title.clima {
  color: rgb(82, 204, 208);
}
.col-category .row .category-title.acao {
  color: rgb(255, 87, 87);
}
.col-category .row .category-title.sons {
  color: rgb(56, 204, 135);
}
.col-category .row .category-title.estado_humano {
  color: rgb(82, 113, 255);
}
.col-category .row .category-title.toque {
  color: #ff74ba;
}
.col-category .row .category-title.eletronico {
  color: rgb(166, 166, 166);
}
.col-category .row .category-title.emocao {
  color: rgb(240, 210, 87);
}
.col-category .row .category-title.comer_beber {
  color: rgb(193, 255, 114);
}
.col-category .row .col-6.col-sm-4 > div {
  position: relative;
  border: 1.5px solid;
  padding: 5px 20px;
  margin: 12.5px 0;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 350ms;
  font-weight: 500;
}
.col-category .row .col-6.col-sm-4 > div:hover {
  transform: scale(1.02);
  color: white;
}
.col-category .row .col-6.col-sm-4 > div svg {
  color: black;
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
  transition: all 350ms;
}
.col-category .row .col-6.col-sm-4 > div svg:hover {
  scale: 1.1;
  color: white;
}
.col-category .row .col-6.col-sm-4 > div svg:active {
  scale: 1.3;
}
.col-category .row .col-6.col-sm-4 .animais {
  border-color: rgb(140, 82, 255);
}
.col-category .row .col-6.col-sm-4 .animais:hover {
  background-color: rgba(140, 82, 255, 0.65);
}
.col-category .row .col-6.col-sm-4 .impactos {
  border-color: rgb(255, 145, 77);
}
.col-category .row .col-6.col-sm-4 .impactos:hover {
  background-color: rgba(255, 145, 77, 0.65);
}
.col-category .row .col-6.col-sm-4 .clima {
  border-color: rgb(82, 204, 208);
}
.col-category .row .col-6.col-sm-4 .clima:hover {
  background-color: rgba(82, 204, 208, 0.65);
}
.col-category .row .col-6.col-sm-4 .acao {
  border-color: rgb(255, 87, 87);
}
.col-category .row .col-6.col-sm-4 .acao:hover {
  background-color: rgba(255, 87, 87, 0.65);
}
.col-category .row .col-6.col-sm-4 .sons {
  border-color: rgb(56, 204, 135);
}
.col-category .row .col-6.col-sm-4 .sons:hover {
  background-color: rgba(56, 204, 135, 0.65);
}
.col-category .row .col-6.col-sm-4 .estado_humano {
  border-color: rgb(82, 113, 255);
}
.col-category .row .col-6.col-sm-4 .estado_humano:hover {
  background-color: rgba(82, 113, 255, 0.65);
}
.col-category .row .col-6.col-sm-4 .toque {
  border-color: #ff74ba;
}
.col-category .row .col-6.col-sm-4 .toque:hover {
  background-color: rgba(255, 116, 186, 0.65);
}
.col-category .row .col-6.col-sm-4 .eletronico {
  border-color: rgb(166, 166, 166);
}
.col-category .row .col-6.col-sm-4 .eletronico:hover {
  background-color: rgba(166, 166, 166, 0.65);
}
.col-category .row .col-6.col-sm-4 .emocao {
  border-color: rgb(240, 210, 87);
}
.col-category .row .col-6.col-sm-4 .emocao:hover {
  background-color: rgba(240, 210, 87, 0.65);
}
.col-category .row .col-6.col-sm-4 .comer_beber {
  border-color: rgb(193, 255, 114);
}
.col-category .row .col-6.col-sm-4 .comer_beber:hover {
  background-color: rgba(193, 255, 114, 0.65);
}
.col-category .row .col-6.col-sm-4 .copied {
  background-color: rgb(108, 255, 108);
  color: white;
}
.col-category .row .col-6.col-sm-4 .copied:hover {
  background-color: rgb(108, 255, 108);
}
.col-category .row .tags {
  text-decoration: none;
  width: fit-content;
  cursor: pointer;
}
.col-category .row .tags:hover {
  color: #0155d4;
  transform: scale(1.12) !important;
}

.col-about .row {
  padding-top: 40px;
  gap: 60px;
  justify-content: center;
}
.col-about .row .juwu,
.col-about .row .pandin {
  width: 90%;
  color: white;
  border-radius: 10px;
  padding: 20px 20px;
  position: relative;
  font-size: 24px;
  font-family: "Dreaming Outloud";
}
.col-about .row .juwu .balloon,
.col-about .row .pandin .balloon {
  position: absolute;
  bottom: 0;
}
.col-about .row .juwu .balloon::before,
.col-about .row .pandin .balloon::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -31px;
  right: 50%;
  height: 30px;
  background-image: none;
  -webkit-border-bottom-right-radius: 80px 50px;
  -moz-border-radius-bottom-right: 80px 50px;
  border-bottom-right-radius: 80px 50px;
  -webkit-transform: translate(0, -2px);
  -moz-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}
.col-about .row .juwu .balloon::after,
.col-about .row .pandin .balloon::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -31.5px;
  right: 50%;
  width: 60px;
  height: 30px;
  background: #fff;
  background-color: rgb(255, 255, 255);
  background-image: none;
  -webkit-border-bottom-right-radius: 40px 50px;
  -moz-border-radius-bottom-right: 40px 50px;
  border-bottom-right-radius: 40px 50px;
  -webkit-transform: translate(-30px, -2px);
  -moz-transform: translate(-30px, -2px);
  -ms-transform: translate(-30px, -2px);
  -o-transform: translate(-30px, -2px);
  transform: translate(-30px, -2px);
}
@media screen and (max-width: 992px) {
  .col-about .row .juwu,
  .col-about .row .pandin {
    font-size: 20px;
  }
}
.col-about .row .juwu {
  background-color: purple;
}
.col-about .row .juwu .balloon {
  left: 80px;
}
.col-about .row .juwu .balloon::before {
  border-right: 60px solid purple;
  background: purple;
  background-color: purple;
}
.col-about .row .pandin {
  background-color: black;
  text-align: end;
}
.col-about .row .pandin .balloon {
  right: 80px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.col-about .row .pandin .balloon::before {
  border-right: 60px solid black;
  background: black;
  background-color: black;
}
.col-about .row .about {
  font-size: 20px;
  opacity: 0;
}
@media screen and (max-width: 992px) {
  .col-about .row {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

