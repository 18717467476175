@font-face {
    font-family: 'Dreaming Outloud';
    src: url('/src/components/fonts/dreaming-outloud-regular.otf') format('opentype');
    
}

$animais: rgb(140, 82, 255);
$impactos: rgb(255, 145, 77);
$clima: rgb(82, 204, 208);
$acao: rgb(255, 87, 87);
$sons: rgb(56, 204, 135);
$estado_humano: rgb(82, 113, 255);
$toque: #ff74ba;
$eletronico: rgb(166, 166, 166);
$emocao: rgb(240, 210, 87);
$comer_beber: rgb(193, 255, 114);

$categories: animais $animais,
    impactos $impactos,
    clima $clima,
    acao $acao,
    sons $sons,
    estado_humano $estado_humano,
    toque $toque,
    eletronico $eletronico,
    emocao $emocao,
    comer_beber $comer_beber;

#root {
    max-width: 100svw;
    overflow-x: hidden;
}

.navbar-custom {
    min-height: 100svh;
    background-color: blue;
    color: white;
    display: flex;
    align-items: flex-start;

    .container-fluid {
        flex-direction: column;
        align-items: start;
        position: sticky;
        top: 8px;

        .logo-box {
            margin: 20px 0;
            text-decoration: none;
            color: white;
            display: flex;

            img {
                max-width: 150px;
            }
        }

        .navbar-toggler {
            transition: outline 0.3s ease;

            &.hover,
            &:not(.collapsed) {
                outline: 2px solid white;
            }

            .navbar-toggler-icon {
                filter: invert(1) brightness(2);

            }
        }

        .navbar-collapse {

            .navbar-nav {
                flex-direction: column;

                .nav-link {
                    color: white;

                    &.active,
                    &:hover {
                        font-weight: 600;
                        color: rgb(61, 210, 255);
                    }
                }

            }
        }
    }


    @media screen and (max-width: 991px) {
        position: fixed;
        top: 0;
        min-height: 50px;
        z-index: 1;

        .container-fluid {
            position: relative;
            top: 0;
            height: 100%;
            flex-direction: row;

            .logo-box {
                margin: 0;
                justify-content: left;
                flex-direction: row;
                align-items: center;
                height: 45px;

                img {
                    max-height: 100%;
                }
            }

            .navbar-toggler {
                position: absolute;
                right: 11px;
            }

        }
    }
}

.col-home {
    display: flex;
    flex-direction: column;
    align-items: center;

    .row-home {
        .icon-animated {
            display: flex;
            justify-content: center;
            align-items: end;

            .large-icon {
                width: 80%;
                margin: 30px 0;

                @media screen and (max-width: 992px) {
                    width: 95%;
                    margin-bottom: 20px;
                }
            }
        }

        .col-md-12 {
            position: relative;

            .search-form {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;

                .search-input {
                    width: 100%;
                    padding: 10px;
                    border-radius: 10px;
                    border: 1px solid rgb(225, 225, 225);
                    background: rgb(223, 223, 223);
                    transition: all 180ms;
                    font-weight: 600;

                    &:focus {
                        background: white;
                        outline: none;
                        box-shadow: 1px 0 0 0 rgb(159, 159, 159), 0 0 0 1px rgb(159, 159, 159), -1px 0 0 0 rgb(159, 159, 159);
                    }

                    &.error {
                        background: white;
                        outline: 1px solid rgb(255, 0, 0);
                    }
                }

                svg {
                    position: absolute;
                    right: 1.5%;
                    font-size: 1.2rem;
                    cursor: pointer;

                    &:hover,
                    &:active {
                        color: blue;
                        transform: scale(1.1);
                    }

                    @media screen and (max-width: 992px) {
                        right: 3%;
                    }
                }


                .suggestions-list {
                    position: absolute;
                    top: 100.9%;
                    left: -0.03%;
                    padding: 10px;
                    width: 100.12%;
                    max-height: 55svh;
                    overflow-y: auto;
                    z-index: 1;
                    list-style: none;
                    /* From https://css.glass */
                    background: rgba(0, 0, 0, 0.222);
                    border-radius: 16px;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    font-weight: 700;
                    text-shadow: 1px 1px black;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;

                    @media screen and (max-width: 992px) {
                        max-height: 60svh;
                    }

                    .title {
                        color: white;
                        font-size: 1.3rem;
                    }

                    .tags {
                        font-size: 0.8rem;
                        color: rgb(212, 212, 212);
                    }
                }


                .has-content {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        .category-row {
            justify-content: center;

            .category-block {
                max-width: 19.1%;
                border-radius: 10px;
                position: relative;
                height: 0;
                padding-bottom: 18%;
                overflow: hidden;
                color: white;
                font-size: 21px;
                font-weight: 600;
                text-align: center;
                cursor: pointer;

                &:nth-child(1) {
                    background: $animais;
                }

                &:nth-child(2) {

                    background: $impactos;
                }

                &:nth-child(3) {
                    background: $clima;
                }

                &:nth-child(4) {
                    background: $acao;
                }

                &:nth-child(5) {
                    background: $sons;
                }

                &:nth-child(6) {
                    background: $estado_humano;
                }

                &:nth-child(7) {
                    background: $toque;
                }

                &:nth-child(8) {
                    background: $eletronico;
                }

                &:nth-child(9) {
                    background: $emocao;
                    color: rgb(207, 30, 43);
                }

                &:nth-child(10) {
                    background: $comer_beber;
                    color: rgb(57, 87, 10);
                }

                .text-block {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    gap: 10px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .svg-inline--fa {
                        height: 3em;

                        @media screen and (max-width:423px) {
                            height: 2.5em;
                        }
                    }
                }

                @media screen and (max-width: 992px) {
                    max-width: 24%;
                    padding-bottom: 22%;

                    @media screen and (max-width:768px) {
                        max-width: 32%;
                        padding-bottom: 28%;
                    }

                    @media screen and (max-width: 577px) {
                        max-width: 46.5%;
                        padding-bottom: 35%;

                        @media screen and (max-width:423px) {
                            max-width: 44.5%;
                            padding-bottom: 50%;
                        }
                    }
                }

                &:hover {

                    svg {
                        transition: all linear 150ms;
                        transform: scale(1.2);
                    }
                }
            }

        }

        @media screen and (max-width:992px) {
            margin: 50px 0 30px 0;
        }
    }


}

.col-category {
    .row {
        padding: 12.5px 0;

        .category-title {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 25px;
            font-weight: 600;

            a {
                position: absolute;
                font-size: 35px;
                left: 1.8%;
                transition: all 350ms;

                &:hover {
                    transform: scale(1.1);
                }

                @media screen and (max-width: 576px) {
                    left: 3%;
                }

            }

            @each $category, $color in $categories {
                a.#{$category} {
                    color: $color;
                }
            }
        }

        @each $category, $color in $categories {
            .category-title.#{$category} {
                color: $color;
            }
        }


        .col-6.col-sm-4 {
            &>div {
                position: relative;
                border: 1.5px solid;
                padding: 5px 20px;
                margin: 12.5px 0;
                border-radius: 5px;
                text-align: center;
                cursor: pointer;
                transition: all 350ms;
                font-weight: 500;

                &:hover {
                    transform: scale(1.02);
                    color: white;
                }

                svg {
                    color: black;
                    position: absolute;
                    right: 3%;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 350ms;

                    &:hover {
                        scale: 1.1;
                        color: white;
                    }

                    &:active {
                        scale: 1.3;
                    }
                }
            }

            @each $category, $color in $categories {
                .#{$category} {
                    border-color: $color;

                    &:hover {
                        background-color: rgba($color, 0.650);
                    }
                }
            }

            .copied {
                background-color: rgb(108, 255, 108);
                color: white;
            }

            .copied:hover {
                background-color: rgb(108, 255, 108);
            }
        }

        .tags {
            text-decoration: none;
            width: fit-content;
            cursor: pointer;

            &:hover {
                color: #0155d4;
                transform: scale(1.12) !important;
            }
        }
    }
}

.col-about {
    .row {
        padding-top: 40px;
        gap: 60px;
        justify-content: center;

        .juwu,
        .pandin {
            width: 90%;
            color: white;
            border-radius: 10px;
            padding: 20px 20px;
            position: relative;
            font-size: 24px;
            font-family: 'Dreaming Outloud';

            .balloon {
                position: absolute;
                bottom: 0;


                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    bottom: -31px;
                    right: 50%;
                    height: 30px;
                    background-image: none;
                    -webkit-border-bottom-right-radius: 80px 50px;
                    -moz-border-radius-bottom-right: 80px 50px;
                    border-bottom-right-radius: 80px 50px;
                    -webkit-transform: translate(0, -2px);
                    -moz-transform: translate(0, -2px);
                    -ms-transform: translate(0, -2px);
                    -o-transform: translate(0, -2px);
                    transform: translate(0, -2px);
                }

                &::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    bottom: -31.5px;
                    right: 50%;
                    width: 60px;
                    height: 30px;
                    background: #fff;
                    background-color: rgb(255, 255, 255);
                    background-image: none;
                    -webkit-border-bottom-right-radius: 40px 50px;
                    -moz-border-radius-bottom-right: 40px 50px;
                    border-bottom-right-radius: 40px 50px;
                    -webkit-transform: translate(-30px, -2px);
                    -moz-transform: translate(-30px, -2px);
                    -ms-transform: translate(-30px, -2px);
                    -o-transform: translate(-30px, -2px);
                    transform: translate(-30px, -2px);
                }
            }

            @media screen and (max-width:992px) {
                font-size: 20px;
            }
        }

        .juwu {
            background-color: purple;

            .balloon {
                left: 80px;

                &::before {
                    border-right: 60px solid purple;
                    background: purple;
                    background-color: purple;
                }
            }
        }

        .pandin {
            background-color: black;
            text-align: end;

            .balloon {
                right: 80px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);

                &::before {
                    border-right: 60px solid black;
                    background: black;
                    background-color: black;
                }
            }
        }

        .about {
            font-size: 20px;
            opacity: 0;
        }

        @media screen and (max-width: 992px) {
            padding-top: 80px;
            padding-bottom: 50px;
        }
    }
}